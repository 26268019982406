import { Autocomplete, Chip, InputBase, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { dateOptions } from 'src/config/constants'
import { CompletedLearningStatus, CompletedLearningStatusString, CompletedLearningType, CurrencyStatus, CurrencyStatusString } from 'src/dtos/HumanResources/TeamMember/TeamMemberTraining.dto'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService'
import SearchIcon from '@mui/icons-material/Search';
import { MessageItem } from 'src/components/errorHandlingSnackbar'

type Props = {
    id: string
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TeamMemberCompletedTraining({id, setMessageItem}:Props) {
    const date = new Date()
    const startYear = date.getMonth() >= 7 ? date.getFullYear() : date.getFullYear() -1;
    const currentFY = `${startYear}-${startYear+1}`

    const [financialYear, setFinancialYear] = useState(currentFY)
    const [searchTerm, setSearchTerm] = useState("")

    const completedTrainingQuery = useQuery({
        queryKey: ["TeamMember.GetCompletedTraining", id, financialYear],
        queryFn: async () => {
            return await TeamMemberService.GetCompletedTraining(id, financialYear)
        },
        initialData: {
            completedTraining: [],
            financialYears: [currentFY]
        }
    })

    useEffect(() => {
        if(completedTrainingQuery.isError)
            setMessageItem({error: completedTrainingQuery.error})
    }, [setMessageItem, completedTrainingQuery.error, completedTrainingQuery.isError])

    return (
        <Stack spacing={1} sx={{paddingTop:"0.5rem"}}>
            <Stack direction={"row"} spacing={2}>
                <Paper
                    component="form"
                    sx={{p: '2px 1px', marginLeft:"1rem", display: 'flex', alignItems: 'center', width:"13rem" }}
                >
                    <SearchIcon color="disabled"/>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Search'
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />

                </Paper>
                <Autocomplete
                    id="FinancialYear"
                    options={completedTrainingQuery.data.financialYears}
                    value={financialYear}
                    onChange={(_e, value) => {
                        setFinancialYear(value)
                    }}
                    disableClearable
                    size={"small"}
                    sx={{width:"10rem"}}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            label="Financial Year"
                        />
                    }
                />
            </Stack>
            {completedTrainingQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer
            )}
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Training Item
                            </TableCell>
                            <TableCell>
                                Completion Status
                            </TableCell>
                            <TableCell>
                                Expiry
                            </TableCell>
                            <TableCell>
                                Currency Status
                            </TableCell>
                            <TableCell>
                                Completion Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { completedTrainingQuery.data.completedTraining.filter(ct => ct.label.toLowerCase().includes(searchTerm.toLowerCase())).map((completedTraining, index) => {
                            let url = ""
                            if(completedTraining.trainingManualEvidenceId){
                                url = `/admin/training/manualEvidence/${completedTraining.trainingManualEvidenceId}`
                            } else if (completedTraining.learningDocumentResponseId && completedTraining.trainingSessionId){
                                url = `/completed/training/trainingSessions/${completedTraining.trainingSessionId}/${completedTraining.learningDocumentResponseId}`
                            } else if (completedTraining.learningItemResponseId && completedTraining.trainingSessionId){
                                url = `/completed/training/trainingSessions/${completedTraining.trainingSessionId}/Attendance/${completedTraining.learningItemResponseId}`
                            } else if(completedTraining.learningDocumentResponseId && completedTraining.type === CompletedLearningType.competencyDocument) { 
                                url = `/completed/training/assessments/${completedTraining.learningDocumentResponseId}`
                            }

                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {url !== "" ?
                                            <Link to={url}>
                                                {completedTraining.label}
                                            </Link>
                                            : 
                                            <Typography>{completedTraining.label}</Typography>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            size='small'
                                            label={CompletedLearningStatusString[completedTraining.status]}
                                            color={completedTraining.status === CompletedLearningStatus.passed ? "success" : "warning"}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {completedTraining.expiry ? new Date(completedTraining.expiry).toLocaleDateString(undefined, dateOptions) : ""}
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            size="small"
                                            label={CurrencyStatusString[completedTraining.currencyStatus]}
                                            color={completedTraining.currencyStatus === CurrencyStatus.current ? "success" 
                                                : completedTraining.currencyStatus === CurrencyStatus.superseded ? "default"
                                                : "default"   
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {completedTraining.completionDate ? new Date(completedTraining.completionDate).toLocaleDateString(undefined, dateOptions) : ""}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default TeamMemberCompletedTraining